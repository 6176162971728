<template>


	<div id="toolspages">

		<div v-if="!working">
			<button class="m-2" @click="initempty()" v-if="1==2">Empty</button>  
		<button class="m-2" @click="renderhelppages()">Parse</button>
		</div>

		<div v-if="working">
			<button class="m-2" disabled="disabled" v-if="1==2">Empty</button>  
		<button class="m-2" disabled="disabled">Parse</button> 
		</div>
		

	<ul class="list-group">
		<li class="list-group-item text-success" v-for="(item,key) in renderedpages" :key="key">{{item.title}} </li>
	</ul>
	<ul class="list-group">
		<li class="list-group-item text-danger" v-for="(item,key) in notrenderedpages" :key="key">{{item.title}}</li>
	</ul>

	<ul class="list-group">
		<table class="">
			<tr v-for="(item,key) in newlinks" :key="key">
				<td class="p-1">{{item.page}}</td>
				<td class="p-1">{{item.label}}</td>
				<td class="p-1">{{item.url}}</td>
				<td class="p-1">{{item.pageid}}</td>
			</tr>
		</table>
	</ul>

	<div style="position: absolute; height: 1px; top: -10000px; left: -10000px;" v-for="(item,key) in temppages" :key="key" v-html="item.body" :id="'bodys'+item.id"></div>

	</div>

</template>

<!-- -->
<style lang ="scss">


</style>

<script type="text/javascript">
	
</script>

<script>

	import axios from 'axios'


	export default{
		name:'items',
		 data(){
		     return{
		     	pages:[],
		     	renderdone:false,
		     	renderedpages:[],
		     	notrenderedpages:[],
		     	temppages:[],
		     	newlinks:[],
		     	working:false,
		     	uri:''
		     }
		 },
		mounted() { 

			let self = this

			

			//https://goflowe.atlassian.net/wiki/api/v2/spaces/713621506/pages

			$('#preloader').addClass('hide')

			/*let hostname = location.hostname;
			let islocalhost = false
			islocalhost = hostname.indexOf('localhost') > -1 ? true : false
			isdev2 = hostname.indexOf('dev2') > -1 ? true : false
			isapp = hostname.indexOf('app') > -1 ? true : false

			const localuri = 'http://localhost:8081'
			const dev2uri = 'https://dev2api.flowe.io'
			const appuri = 'https://appapi.flowe.io'*/

			this.uri = 'https://appapi.flowe.io'// islocalhost ? localuri : ( isdev2 ? dev2uri : appuri ) 

		},
		methods : {

			renderhelppages(){

				let self = this

				this.initempty( function(){

					self.initparse()

				})
			},

			initempty( callback ){

				

				

				this.working = true

				let self = this

				axios.get( this.uri + '/Tools/Pagesempty' ).then( function( data ){
					self.pages = []
					self.renderedpages = []
					self.working = false

					if( callback ){
						callback()
					}
				})
			},

			initparse(){
				this.getpages( '/wiki/api/v2/spaces/713621506/pages' )
			},

			getpages( pageurl ){

				let self = this

				this.working = true

				axios.get( this.uri + '/Tools/Pages?page='+pageurl ).then( function( data ){

					for( var i in data.data?.results ){
						self.pages.push( data.data?.results[i] )
					}


					if( data.data?._links?.next ){
						self.getpages( data.data?._links?.next )
					}else{
						self.renderpages()
					}

					 //= 

				})
			},

			renderpages(){

				let self = this

				self.working = true

				let newpages = []

				let newlinks = []

				//if( this.renderdone==true ){

				let donecount = this.pages.length
				let donecntr = 0

				for( var i in this.pages ){

					
					let page = this.pages[i]
					let payload = { "id":page.id, "title":page.title, "parent":page.parentId, "body":"", "position": page.position }


					//axios.get( 'http://localhost:8081/Tools/Pages?page='+pageurl ).then( function( data ){
					//"720863239", "720863586", "720830471", "2393210896", "2393473025","720863764","713621620" 
					let donotinclude = ["720863239", "null"]

					if( donotinclude.indexOf( page.id ) == -1 ){

						let rawuri = '/wiki/api/v2/pages/'+ page.id +'?body-format=view'
						let encoded = encodeURI(rawuri);


						axios.get( self.uri + '/Tools/Pages?page='+encoded ).then(function( response ){

							let newbody = ""
							newbody = response.data.body.view.value

							//let newdiv = document.createElement("div")

							//newdiv.innerHTML = newbody

							//for(const link of newbody.links) {
							//	newlinks.push(link)
							//}

							payload.body = newbody

							self.temppages.push(payload)

							self.$nextTick(() => {
						       let fragment = document.getElementById("bodys"+payload.id);
						       let links = fragment.getElementsByTagName('a');
						      

						       if( links.length > 0 ){
						       		for( var i=0; i<links.length;i++){

						       			let aurl = links[i].href
						       			var regex = /\d{9}/g;

						       			let numtd = ""


						       			if( aurl.length > 0 ){

						       				let numbers = aurl.match(regex);						       			

							       			if( numbers && numbers[0]){
							       				numtd = "https://app.flowe.io/help/"+numbers[0]
							       			}

						       			}
						       			
						       			

						       			//numbers = numbers[0] ? numbers[0] : ""
						       			self.newlinks.push( { "page":page.title, "label":links[i].innerText, "url":aurl, "pageid":numtd } )

						       			// console.log(  )
						       		}
						       }else{

						       }

						      });

							

							const URLparams = new URLSearchParams(payload)
							axios.post(  self.uri + '/Tools/Pagessave', URLparams.toString() ).then(function( response ){

								self.renderedpages.push( response.data.data )

								donecntr++


							}).catch( function(){
								self.notrenderedpages.push( response.data.data )

								donecntr++
							})
							
						})	

					}

	

					//})

					

				}

				if( donecntr>= donecount ){

					self.working = false
				}

				//}


			}
		},
		computed:{

			
		},
	}
	//  
</script>